import styled from "styled-components";
export const Title=styled.h1`
	text-align:center;
	letter-spacing: 1px;
`
export const Presentation= styled.p`
	text-align:center;
`
export const RightDiv = styled.div`
	text-align:right;
`
export const Love = styled.a`
	padding:10px ;
	color:grey;
`